import React, { Component, Fragment } from 'react';
// import { HashRouter, Switch, Route ,withRouter} from 'react-router-dom';
// import Header from './layout/header/header';
import Footer from './layout/footer/footer';
import {BrowserRouter as Router,
  Switch,
  Route, 
  Link } from "react-router-dom"; 

import $ from 'jquery';

import './App.css';
// import './Vendor.js';
import './Vendor.js';


import Index2 from './pages/Index/index2';

import Scrolltop from './layout/back-to-top';

class App extends React.Component {
  
  // UNSAFE_componentWillMount()
  // {
  //   this.props.getProducts();
  // }
  // getUrl(pathname) {
  //   let pathArray = pathname.split('/');
  //   return `/${pathArray[1]}` === '/coming-soon' ? true : `/${pathArray[1]}` === '/maintenance' ? true : `/${pathArray[1]}` === '/page-not-found' ? true : false;
  // }
  // setHeader(pathname) {
  //   let pathArray = pathname.split('/');
  //   return `/${pathArray[1]}` === '/index2' ? true : `/${pathArray[1]}` === '/index3' ? true : `/${pathArray[1]}` === '/index2test' ? true : `/${pathArray[1]}` === '/index4' ? true : `/${pathArray[1]}` === '/index5' ? true : `/${pathArray[1]}` === '/index9' ? true  : false;
 
  // }
  render() {
    return (
      <Fragment>
        <div className="page-wrapper">
          <Router>
          <Switch>
            <Route exact path="/" component={Index2} />
          </Switch>
          </Router>
          <Footer />
          <Scrolltop />
        </div>
      </Fragment>
    );
  }
}

// const AppMapStateToProps = state => {
//   return {
//     products: state.data.products
//   };
// };

// const AppMapDispatchToProps = dispatch => {
//   return {
//     getProducts: () => {
//       dispatch(getProducts());
//     }
//   };
// };


export default App;

