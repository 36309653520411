import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';

//Component

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './index.css';
import App from './App.js';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
import common_po from "./translations/po/common.json";
import HttpsRedirect from 'react-https-redirect';

i18next.init({
   interpolation: { escapeValue: false },  // React already does escaping
   lng: 'en',
   resources: {
      en: {
         common: common_en
      },
      es: {
         common: common_es
      },
      po: {
         common: common_po
      }

   }
});

class Root extends React.Component {
    render() {
        return(
         <I18nextProvider i18n={i18next}>
            <HttpsRedirect>

                <BrowserRouter>
                   <Switch>
                      <Route path="/" component={App} />
                   </Switch>
                </BrowserRouter>
                </HttpsRedirect>

                </I18nextProvider>
          );
       }
   }
 
 ReactDOM.render(<Root />, document.getElementById('root'));
 
