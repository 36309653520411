import React, { Component } from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Videobox1 from "../vediolightbox.js/videobox1";
import Draggable from "react-draggable";
import dragBall from "../../assets/images/icon/football.svg";
import rivaldo from "../../assets/images/hero/rivaldoFitline.jpeg";
import { useTranslation } from 'react-i18next';

// class Herosection1 extends Component {
  const Herosection1 = () => {

    const {t, i18n} = useTranslation('common');
    const i18nTranslation = useTranslation('common');
    return (
      <section className="position-relative overflow-hidden pb-0">
        <div className="position-relative">
        <div className="translationBtn" onClick={()=> i18n.changeLanguage('en')}>
          ENG
          </div> 
          <div className="translationBtn" onClick={()=> i18n.changeLanguage('es')}>
            ESP
          </div>
          <div className="translationBtn" onClick={()=> i18n.changeLanguage('po')}>
            POR
          </div>


        </div>

        <Draggable>
          <img
            className="draggableBall"
            src={dragBall}
            alt=""
          />
        </Draggable>

        <div className="container">
          <Videobox1 i18nTranslation={i18nTranslation} />
          {/* / .row */}
        </div>

        {/* / .container */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              
              {/* <div className="heroparallax"> */}
              <ParallaxProvider>
                <Parallax className="heroparallax" x={[-45, 20]}>
                  <img
                    className="img-fluid thumbnail onLgSmallify"
                    src={rivaldo}
                    alt=""
                  />
                </Parallax>
              </ParallaxProvider>
            </div>
          </div>
        </div>
      </section>
    );
  }

export default Herosection1;
